import React, { Fragment } from 'react';
import Layout from './Layout';
import { Link } from 'react-router-dom'
import AOS from 'aos';
import 'aos/dist/aos.css'
import { auth, getCursosRef, getCoberturasRef, getMunicipiosZonasRef, getComunidadesRef, getComunidadesDisponiblesRef } from '../firebase/firebase.utils'
import { Helmet } from 'react-helmet'
import { getLocation } from '../firebase/locationUtils';
import { getURLVisit } from '../firebase/locationUtils';
import Ads from './Ads';
class Beneficios extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            estados: [],
            loading: true
        }
    }
    random_rgba = () => {
        var o = Math.round, r = Math.random, s = 185;
        return 'rgba(' + o(r() * 50) + ',' + 0 + ',' + o(r() * s) + 95 + ',' + .5 + ')';
    }
    obtenerComunidades = async () => {
        const coberturasRef = await getCoberturasRef();
        const estados = []
        await coberturasRef.get().then(qSnapshot => {
            qSnapshot.forEach(async estado => {
                const municipiosZonas = []
                const municipiosZonasRef = getMunicipiosZonasRef(estado.id)
                await municipiosZonasRef.get().then(q2Snapshot => {
                    q2Snapshot.forEach(async municipio => {
                        const comunidades = [];
                        const comunidadesRef = getComunidadesDisponiblesRef(estado.id, municipio.id)
                        await comunidadesRef.get().then(q2Snapshot => {
                            q2Snapshot.forEach(async comunidad => {
                                var comunidadRawData = comunidad.data()
                                comunidadRawData.id = comunidad.id
                                comunidades.push(comunidadRawData)
                            })
                            var municipioRawData = municipio.data();
                            municipioRawData.id = municipio.id;
                            municipioRawData.comunidades = comunidades
                            municipiosZonas.push(municipioRawData)
                        })
                        this.setState({ estados: estados, loading: false })
                    })
                    var estadosRawData = estado.data();
                    estadosRawData.id = estado.id;
                    estadosRawData.municipioszonas = municipiosZonas
                    estados.push(estadosRawData)
                })

            })
        })
    }
    componentDidMount() {
        AOS.init({
        })
        let event = new Event('js');
        window.dispatchEvent(event);
        document.title = 'Red Itmex - Cobertura';
        this.obtenerComunidades()
        this.registrarVisita()
    }
    registrarVisita = async () =>{
        await getLocation()
        await getURLVisit(this.props.history,"pRNV5AvyjEsxHfjqkxzh")
    }
    render() {
        return (
            <div className=" ">
                <Layout title="Red Itmex - Cobertura">
                    <Helmet>
                        <meta name="description" content="Servicio de Internet en Ixmiquilpan, Cardonal, Tasquillo y Actopan. Descubre la cobertura con la que contamos actualmente. Estamos abriendo nuevas sucursales, si no ves tu comunidad aquí, pregunta por la disponibilidad." />
                        <meta name="robots" content="index" />
                        <meta property="og:url" content="https://www.reditmex.com/cobertura" />
                        <meta property="og:type" content="website" />
                        <meta property="og:title" content="Red Itmex - Cobertura" />
                        <meta property="og:description" content="Servicio de Internet en Ixmiquilpan, Cardonal, Tasquillo y Actopan. Descubre la cobertura con la que contamos actualmente. Estamos abriendo nuevas sucursales, si no ves tu comunidad aquí, pregunta por la disponibilidad." />
                        <meta property="og:image" content="https://www.reditmex.com/assets/img/FBThumbnail01.jpg" />
                        <meta property="fb:app_id" content="418170456085047" />
                    </Helmet>
                    
                    <div className="loader" style={{ display: this.state.loading ? 'flex' : 'none' }}>
                        <div className="Box"></div>
                    </div>
                    <main id="content" role="main" style={{ display: this.state.loading ? 'none' : 'block' }}>
                        <div id="SVGHero" className="position-relative bg-img-hero space-top-2" style={{ backgroundImage: 'url(/assets/img/fotos/ixmf5.jpg)', backgroundPosition: 'center' }}>
                            <div className="container space-top-2 space-bottom-3 space-md-3 space-bottom-lg-4">
                                <div className="w-md-65 w-lg-50">
                                    <h1 className="text-white">Grandes Beneficios</h1>
                                    <h1 className=" text-white font-weight-medium">¡Disfruta los beneficios de la red!</h1>
                                </div>
                            </div>
                            {/* <!-- SVG Background -->*/}
                            <figure className="position-absolute right-0 bottom-0 left-0">
                                <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none" x="0px" y="0px" width="100%" height="85px" viewBox="0 0 1920 107.7" style={{ marginBottom: '-8px', enableBackground: 'new 0 0 1920 107.7' }} xmlSpace="preserve" className="injected-svg js-svg-injector" data-parent="#SVGHero">
                                    <style type="text/css">
                                    </style>
                                    <path className="wave-9-bottom-0 fill-white" d="M0,107.7V69.8c0,0,451-54.7,960-5.4S1920,0,1920,0v107.7H0z"></path>
                                    <path className="wave-9-bottom-1 fill-white" opacity=".3" d="M0,107.7v-81c0,0,316.2-8.9,646.1,54.5s794.7-114.1,1273.9-38v64.5H0z"></path>
                                </svg>
                            </figure>
                            {/* <!-- End SVG Background -->*/}
                        </div>
                        <div className="container">
                            <center><span className="btn btn-xs btn-soft-success btn-pill m-4"></span></center>

                            <div className="space-2 space-bottom-lg-3">
                                            <div className="row justify-content-lg-center" >
                                                <div className="col-lg-8">
                                                    <div id="basics" className="space-bottom-1">
                                                        <h2>BENEFICIOS ITMEX</h2>
                                                            <div id={"ComoFuncionaAcordion"} >
                                                                <div className="card shadow-none mb-3">
                                                                    <div className="card-header card-collapse" id={"ComoFunciona"}>
                                                                        <a className="btn btn-link btn-block d-flex justify-content-between card-btn collapsed bg-white px-0" href="" role="button" data-toggle="collapse" data-target={"#ComoFuncionaC"} aria-expanded="false" aria-controls={"ComoFuncionaC"}>
                                                                            ¿Cómo Funciona?
                                                                            <span className="card-btn-toggle">
                                                                                <span className="card-btn-toggle-default"><i className="fas fa-plus"></i></span>
                                                                                <span className="card-btn-toggle-active"><i className="fas fa-minus"></i></span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                    <div id={"ComoFuncionaC"} className="collapse" aria-labelledby={"ComoFunciona"} data-parent={"#ComoFuncionaAcordion"}>
                                                                            <div className="card-body px-0">
                                                                                <div className="row mb-1">
                                                                                    <div className="col-12 mb-1 align-self-center">
                                                                                        <h3>¿Como Funciona?</h3>
                                                                                        <p>1. Recomienda ITMEX a tus amigos, familiares y conocidos.</p>
                                                                                        <p>2. Asegúrate de que mencionen tu número de servicio al suscribirse.</p>
                                                                                        <p>3. Gana $200 MXN en descuentos por cada recomendación desde la primera instalación.</p>
                                                                                        <p>Gana descuentos aplicables a tu servicio de internet. Cuantas más personas recomiendes, más descuentos acumulas. Entre más recomiendes, podrás adquirir premios exclusivos. ¡Permanece atento para descubrir todas las sorpresas que ITMEX tiene preparadas para ti!</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id={"TablaBeneficiosAcordion"} >
                                                                <div className="card shadow-none mb-3">
                                                                    <div className="card-header card-collapse" id={"TablaBeneficios"}>
                                                                        <a className="btn btn-link btn-block d-flex justify-content-between card-btn collapsed bg-white px-0" href="" role="button" data-toggle="collapse" data-target={"#TablaBeneficiosC"} aria-expanded="false" aria-controls={"TablaBeneficiosC"}>
                                                                            Tabla de Beneficios
                                                                            <span className="card-btn-toggle">
                                                                                <span className="card-btn-toggle-default"><i className="fas fa-plus"></i></span>
                                                                                <span className="card-btn-toggle-active"><i className="fas fa-minus"></i></span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                    <div id={"TablaBeneficiosC"} className="collapse" aria-labelledby={"TablaBeneficios"} data-parent={"#TablaBeneficiosAcordion"}>
                                                                            <div className="card-body px-0">
                                                                                <div className="row mb-1">
                                                                                    <div className="col-12 mb-1 align-self-center">

                                                                                        <table className="table table-bordered table-hover">
                                                                                            <thead>
                                                                                                <th>RECOMENDADOS</th>
                                                                                                <th>BENEFICIOS</th>
                                                                                            </thead>
                                                                                            <tbody> 
                                                                                                <tr>
                                                                                                    <td>10</td>
                                                                                                    <td>6 meses de internet gratis + Asistente de voz Alexa. Todas tus recomendaciones valen $300 para siempre.</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>9</td>
                                                                                                    <td>Saldo a favor de $400.00 (repartido en 2 mensualidades de $200.00 cada una).</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>8</td>
                                                                                                    <td>Todas tus recomendaciones valen $400 por un año + Suéter y playera ITMEX.</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>7</td>
                                                                                                    <td>Saldo a favor de $300.00 (repartido en 2 mensualidades de $150.00 cada una).</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>6</td>
                                                                                                    <td>Saldo a favor de $300.00 (repartido en 2 mensualidades de $150.00 cada una).</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>5</td>
                                                                                                    <td>Todas tus recomendaciones valen $300 por un año + 2 meses de internet gratis + Termo ITMEX.</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>4</td>
                                                                                                    <td>Saldo a favor de $200.00 (repartido en 2 mensualidades de $100.00 cada una).</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>3</td>
                                                                                                    <td>1 mes de internet gratis + Taza ITMEX.</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>2</td>
                                                                                                    <td>Saldo a favor de $200.00 (repartido en 2 mensualidades de $100.00 cada una).</td>
                                                                                                </tr>
                                                                                                <tr>
                                                                                                    <td>1</td>
                                                                                                    <td>Saldo a favor de $200.00 (repartido en 2 mensualidades de $100.00 cada una).</td>
                                                                                                </tr>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div id={"TCAcordion"} >
                                                                <div className="card shadow-none mb-3">
                                                                    <div className="card-header card-collapse" id={"TC"}>
                                                                        <a className="btn btn-link btn-block d-flex justify-content-between card-btn collapsed bg-white px-0" href="" role="button" data-toggle="collapse" data-target={"#TCC"} aria-expanded="false" aria-controls={"TCC"}>
                                                                            Términos y Condiciones
                                                                            <span className="card-btn-toggle">
                                                                                <span className="card-btn-toggle-default"><i className="fas fa-plus"></i></span>
                                                                                <span className="card-btn-toggle-active"><i className="fas fa-minus"></i></span>
                                                                            </span>
                                                                        </a>
                                                                    </div>
                                                                    <div id={"TCC"} className="collapse" aria-labelledby={"TC"} data-parent={"#TCAcordion"}>
                                                                        <div className="card-body px-0">
                                                                            <div className="row mb-1">
                                                                                <div className="col-12 mb-1 align-self-center">
                                                                                    <div class="section">
                                                                                        <h3>Elegibilidad</h3>
                                                                                        <ul>
                                                                                            <li>Los beneficios aplican solo para clientes actuales de ITMEX que recomienden el servicio a nuevos clientes.</li>
                                                                                            <li>Los nuevos clientes recomendados deben no haber estado suscritos a ITMEX en los últimos 12 meses.</li>
                                                                                        </ul>
                                                                                    </div>

                                                                                    <div class="section">
                                                                                        <h3>Requisitos de las Recomendaciones</h3>
                                                                                        <ul>
                                                                                            <li>Cada recomendación debe culminar en la instalación y activación exitosa del servicio.</li>
                                                                                            <li>Los beneficios se aplican a partir del primer mes de activación del servicio del recomendado.</li>
                                                                                        </ul>
                                                                                    </div>

                                                                                    <div class="section">
                                                                                        <h3>Limitaciones</h3>
                                                                                        <ul>
                                                                                            <li>Los beneficios no son acumulables con otras promociones o descuentos.</li>
                                                                                            <li>ITMEX puede cambiar o cancelar los beneficios sin previo aviso.</li>
                                                                                            <li>Los beneficios no son canjeables por dinero en efectivo.</li>
                                                                                        </ul>
                                                                                    </div>

                                                                                    <div class="section">
                                                                                        <h3>Condiciones Generales</h3>
                                                                                        <ul>
                                                                                            <li>Los términos de los beneficios pueden variar según las políticas internas de ITMEX.</li>
                                                                                            <li>Los clientes deben estar al corriente en sus pagos para ser elegibles para los beneficios.</li>
                                                                                        </ul>
                                                                                    </div>

                                                                                    <div class="section">
                                                                                        <h3>Consideraciones</h3>
                                                                                        <ul>
                                                                                            <li><strong>Periodo de Validación:</strong> Los beneficios se acreditarán dentro de los 30 días siguientes a la activación del servicio del recomendado.</li>
                                                                                            <li><strong>Acceso a Rifa Anual:</strong> Todos los clientes que hayan hecho recomendaciones exitosas tendrán acceso a una rifa anual de gadgets.</li>
                                                                                            <li><strong>Restricciones de Tiempo:</strong> Los beneficios deben ser utilizados dentro de 18 meses desde la fecha de acreditación. Después de 18 meses desde la primera recomendación, se reinician las recomendaciones y el cliente comienza de nuevo con la tabla de beneficios.</li>
                                                                                            <li><strong>Restricciones de Beneficiarios:</strong> Los beneficios no son transferibles; solo el cliente recomendador puede utilizarlos.</li>
                                                                                        </ul>
                                                                                    </div>

                                                                                    <div class="section">
                                                                                        <h3>Cómo Aplicar</h3>
                                                                                        <ul>
                                                                                            <li>En el día de la instalación del nuevo servicio, el nuevo usuario debe mencionar quién lo recomendó. En ese momento, se documentará la información del recomendador.</li>
                                                                                        </ul>
                                                                                    </div>

                                                                                    <div class="section">
                                                                                        <h3>Registro de Recomendaciones</h3>
                                                                                        <ul>
                                                                                            <li>Las recomendaciones deben ser registradas en el sistema de ITMEX en el momento de la instalación del servicio para ser válidas.</li>
                                                                                            <li>Los clientes pueden verificar el estado de sus recomendaciones llamando al centro de atención al cliente.</li>
                                                                                        </ul>
                                                                                    </div>

                                                                                    <div class="section">
                                                                                        <h3>Proceso a seguir en caso de que se aplique un descuento a un usuario que tiene saldo a favor</h3>
                                                                                        <ul>
                                                                                            <li>Cuando se aplique el descuento y junto con el saldo a favor el resultado de la cuenta sea CERO, se debe cambiar el estado de la factura a pagada para evitar que se corte el servicio. Ya que la forma de realizar cortes se basa en las facturas pendientes del mes en curso.</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                <div className="position-relative z-index-2 text-center">
                                    <div className="d-inline-block font-size-1 border bg-white text-center rounded-pill py-3 px-4">
                                        ¿Tienes pregúntas en tus beneficios? <Link className="d-block d-sm-inline-block font-weight-bold ml-sm-3" to="/contacto">Contáctanos <span className="fas fa-angle-right ml-1"></span></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                    
                    {/* <section className="row m-md-3 mt-3 mb-3">
                        <div className="col-md-6 p-0 text-center">
                            <Ads />
                        </div>
                        <div className="col-md-6 p-0 text-center">
                            <Ads />
                        </div>
                    </section> */}
                </Layout>
            </div>
        )
    }
};
export default Beneficios;