import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { getUserData } from '../auth/auth.utils'

const Menu = ({ history }) => {
    const userData = getUserData()
    return (
 
        <div>
            <header id="header" className="header header-box-shadow-on-scroll  header-bg-dark header-show-hide"
                data-hs-header-options='{
            "fixMoment": 1000,
            "fixEffect": "slide"
          }'>

                <div className="header-section">
                    <div id="logoAndNav" className="px-4 px-md-6 mb-2 menu30">
                        {/* <!-- Nav -->*/}
                        <nav className="js-mega-menu navbar navbar-expand-lg">
                            {/* <!-- Logo -->*/}
                            <Link to="/" className="navbar-brand" aria-label="Front">
                                <img src="/assets/img/logos/LOGOMINNEGRO.png" alt="Red Itmex Logo" />
                            </Link>
                            {/* <!-- End Logo -->*/}

                            {/* <!-- Responsive Toggle Button -->*/}
                            <button type="button" className="navbar-toggler btn btn-icon btn-sm rounded-circle"
                                aria-label="Toggle navigation"
                                aria-expanded="false"
                                aria-controls="navBar"
                                data-toggle="collapse"
                                data-target="#navBar">
                                <span className="navbar-toggler-default">
                                    <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor" d="M17.4,6.2H0.6C0.3,6.2,0,5.9,0,5.5V4.1c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,5.9,17.7,6.2,17.4,6.2z M17.4,14.1H0.6c-0.3,0-0.6-0.3-0.6-0.7V12c0-0.4,0.3-0.7,0.6-0.7h16.9c0.3,0,0.6,0.3,0.6,0.7v1.4C18,13.7,17.7,14.1,17.4,14.1z" />
                                    </svg>
                                </span>
                                <span className="navbar-toggler-toggled">
                                    <svg width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z" />
                                    </svg>
                                </span>
                            </button>
                            {/* <!-- End Responsive Toggle Button -->*/}

                            {/* <!-- Navigation -->*/}
                            <div id="navBar" className="collapse navbar-collapse">
                                <div className="navbar-body header-abs-top-inner">
                                    <ul className="navbar-nav">
                                        {/* <!-- Home -->*/}
                                        <li className="hs-has-mega-menu navbar-nav-item">
                                            <a id="homeMegaMenu" className="hs-mega-menu-invoker nav-link nav-link-toggle" aria-haspopup="true" aria-expanded="false">Servicio de Internet</a>

                                            {/* <!-- Home - Mega Menu -->*/}
                                            <div className="hs-mega-menu dropdown-menu w-100" aria-labelledby="homeMegaMenu">
                                                <div className="row no-gutters">
                                                    <div className="col-lg-6">
                                                        {/* <!-- Banner Image -->*/}
                                                        <div className="navbar-banner" style={{ minHeight: '400px', backgroundImage: 'url(/assets/img/img1.jpg)', backgroundPosition: 'center center' }}>
                                                            <div className="navbar-banner-content">
                                                                <div className="mb-4">
                                                                    <span className="h2 d-block text-white">Conéctate y Descubre</span>
                                                                    <p className="text-white">Explora infinitas posibilidades con el nuevo internet.</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <!-- End Banner Image -->*/}
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="row mega-menu-body">
                                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                                <span className="d-block h5">Compañía</span>
                                                                <Link className="dropdown-item" to="/nosotros">Nosotros</Link>
                                                                <Link className="dropdown-item" to="/podcasts">Podcasts</Link>
                                                                <Link className="dropdown-item" to="/concursos">Concursos</Link>
                                                                <Link className="dropdown-item" to="/becas">Becas</Link>
                                                                <Link className="dropdown-item" to="/blog">Blog</Link>
                                                                <Link className="dropdown-item" to="/documentacion">Documentación</Link>
                                                                <Link className="dropdown-item" to="/privacidad">Privacidad</Link>
                                                            </div>

                                                            <div className="col-sm-6">
                                                                <span className="d-block h5">Internet</span>
                                                                <div className="mb-3">
                                                                <Link className="dropdown-item" to="/promociones">Promociones</Link>
                                                                <Link className="dropdown-item" to="/beneficios">Beneficios</Link>
                                                                <Link className="dropdown-item" to="/cobertura">Cobertura <span className="badge badge-primary badge-pill ml-2">NUEVAS</span></Link>
                                                                    <a className="dropdown-item" href="/#planesInternet">Planes</a>
                                                                    <Link className="dropdown-item" to="/internet/faq">Preguntas Frecuentes</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- End Home - Mega Menu -->*/}
                                        </li>
                                        {/* <!-- End Home -->*/}
                                        {/* <!-- Home -->*/}
                                        <li className="hs-has-mega-menu navbar-nav-item">
                                            <a id="homeMegaMenu" className="hs-mega-menu-invoker nav-link nav-link-toggle" aria-haspopup="true" aria-expanded="false">Academia</a>

                                            {/* <!-- Home - Mega Menu -->*/}
                                            <div className="hs-mega-menu dropdown-menu w-100" aria-labelledby="homeMegaMenu">
                                                <div className="row no-gutters">
                                                    <div className="col-lg-6">
                                                        {/* <!-- Banner Image -->*/}
                                                        <div className="navbar-banner" style={{ minHeight: '400px', backgroundImage: 'url(/assets/img/img2.jpg)', backgroundPosition: 'center center' }}>
                                                            <div className="navbar-banner-content">
                                                                <div className="mb-4">
                                                                    <span className="h2 d-block text-white">Aprende y Crece</span>
                                                                    <p className="text-white">¡No dejes de aprender y alcanza nuevos límites!</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* <!-- End Banner Image -->*/}
                                                    </div>

                                                    <div className="col-lg-6">
                                                        <div className="row mega-menu-body">
                                                            <div className="col-sm-6 mb-3 mb-sm-0">
                                                                <span className="d-block h5">Academia</span>
                                                                <Link className="dropdown-item" to="/academia/">Explora</Link>
                                                                <Link className="dropdown-item" to="/academia/cursos/">Cursos <span className="badge badge-primary badge-pill ml-2">NUEVAS</span></Link>
                                                                <Link className="dropdown-item" to="/academia/faq">Preguntas Frecuentes</Link>
                                                            </div>
                                                            <div className="col-sm-6">
                                                                <span className="d-block h5">Mi Trayectoria</span>
                                                                <div className="mb-3">
                                                                    <Link className="dropdown-item" to={`/perfil/miscursos/`}>Mis Cursos</Link>
                                                                    <Link className="dropdown-item" to={`/perfil/`}>Mi Perfil</Link>
                                                                    <Link className="dropdown-item" to={`/perfil/codigo`}>Canjear Código</Link>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <!-- End Home - Mega Menu -->*/}
                                        </li>
                                        {/* <!-- End Home -->*/}
                                        {/* <!-- ProfilePic -->*/}
                                        <li className="navbar-nav-last-item" style={{ marginRight: '15px' }}>
                                            <Link to="/login">
                                                <img className="avatar-img" alt="Profile Pic" style={{ width: '30px' }} src={userData ? userData.photoURL : "/assets/img/160x160/img22.png"} />
                                                <span style={{ color: '#000000', fontWeight: '500' }}> {userData ? userData.displayName : 'Inicia Sesión'}</span>
                                            </Link>
                                        </li>
                                        {/* <!-- End ProfilePic -->*/}

                                        {/* <!-- Button -->*/}
                                        <li className="navbar-nav-last-item">
                                            <Link className="btn btn-sm btn-primary transition-3d-hover" to="/contacto">
                                                ¡Llámanos!
                  </Link>
                                        </li>
                                        {/* <!-- End Button -->*/}
                                    </ul>
                                </div>
                            </div>
                            {/* <!-- End Navigation -->*/}
                        </nav>
                        {/* <!-- End Nav -->*/}
                    </div>
                </div>
            </header>
        </div >
    )
}
export default withRouter(Menu);